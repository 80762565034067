<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(updateDiscountCode)"
        method="post"
        id="check-form"
      >
        <v-container>
          <v-row class="pt-10 mt-10">
            <!-- course input tittel of the course -->
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.course }}<span class="red--text">(*)</span>
              </div>
            </v-col>
            <!-- course choosed by admin -->
            <v-col class="mx-auto pa-0  " cols="10">
              <v-container>
                <v-row>
                  <v-col cols="5" class="pa-0 pr-6 mb-5">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        max-width="100%"
                        outlined
                        class="grey lighten-3"
                        :elevation="hover ? 12 : 2"
                        :class="{ 'on-hover': hover }"
                      >
                        <v-list-item>
                          <v-list-item-content class="pa-0">
                            <div class="overline">
                              <div class="mt-1">
                                {{ discountCodeInfo.course.title }}
                              </div>
                              <div>
                                {{ courseCurrencyPrice }}
                              </div>
                              <router-link
                                class="text-decoration-underline blue--text"
                                :to="{
                                  path: '/course',
                                  query: {
                                    course_slug: discountCodeInfo.course.slug,
                                    viewType: 1
                                  }
                                }"
                              >
                                link
                              </router-link>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <!-- number of times usable by the user -->
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.payment }}<span class="red--text mr-4">(*)</span>
              </div>
              <div class="ml-4 mt-5">
                <v-row align="center">
                  <v-select
                    :items="paymentMethod"
                    class="shrink money-box"
                    dense
                    item-text="label"
                    v-model="discountCodeInfo.type"
                    outlined
                    color="primary"
                  ></v-select>
                  <vuetify-money
                    v-model="discountCodeInfo.amount_money"
                    v-bind:label="''"
                    v-bind:placeholder="'Nhập số tiền khuyến mại'"
                    v-bind:readonly="false"
                    v-bind:disabled="false"
                    v-bind:outlined="true"
                    v-bind:clearable="true"
                    v-bind:valueWhenIsEmpty="''"
                    v-bind:options="options"
                    class="money-box-input"
                  />

                  <div class="icon-hnr pa-2 money__icon-fix">
                    <v-icon>
                      mdi-cash
                    </v-icon>
                  </div>
                </v-row>
                <span class="validate-error text-danger text-danger">
                  {{ paymentNumberErrorMessage }}
                </span>
              </div>
            </v-col>
            <!-- promotion code -->
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.amountUsed }}<span class="red--text">(*)</span>
              </div>
              <div class="ml-4 mt-5" style="max-width: 600px">
                <ValidationProvider
                  rules="required|minmax:0,9"
                  v-slot="{ errors }"
                >
                  <v-row align="center">
                    <v-text-field
                      class="hnr-input input-amount-used"
                      :value="discountCodeInfo.max_of_use"
                      outlined
                      single-line
                      dense
                      v-model="discountCodeInfo.max_of_use"
                    ></v-text-field>
                  </v-row>
                  <span class="validate-error text-danger text-danger">
                    {{ errors[0] }} {{ usedNumberErrorMessage }}
                  </span>
                </ValidationProvider>
              </div>
            </v-col>
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.code }}<span class="red--text">(*)</span>
              </div>
              <div class="ml-4 mt-4">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <v-row align="center">
                    <v-text-field
                      class="hnr-input input-hnr"
                      :value="discountCodeInfo.discount_code"
                      outlined
                      single-line
                      dense
                      v-model="discountCodeInfo.discount_code"
                      :label="lang.title.code"
                    ></v-text-field>
                    <div class="icon-hnr pa-2" v-on:click="generateCode">
                      <v-icon>
                        mdi-cached
                      </v-icon>
                    </div>
                  </v-row>
                  <span class="validate-error text-danger text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </v-col>
            <!-- starting date -->
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.startDate }}<span class="red--text">(*)</span>
              </div>
              <div class="ml-4 mt-4">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <v-row align="center" v-on:click="dateStartBox = true">
                    <v-text-field
                      class="hnr-input input-hnr"
                      :value="discountCodeInfo.start_time"
                      outlined
                      single-line
                      dense
                      v-model="discountCodeInfo.start_time"
                    ></v-text-field>
                    <div class="icon-hnr pa-2" v-on:click="dateStartBox = true">
                      <v-icon>
                        mdi-calendar
                      </v-icon>
                    </div>
                  </v-row>
                  <span class="validate-error text-danger text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </v-col>
            <!--ending date-->
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.endDate }}<span class="red--text">(*)</span>
              </div>
              <div class="ml-4 mt-4">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <v-row align="center" v-on:click="dateEndBox = true">
                    <v-text-field
                      class="hnr-input input-hnr"
                      :value="discountCodeInfo.end_time"
                      outlined
                      single-line
                      dense
                      v-model="discountCodeInfo.end_time"
                    ></v-text-field>
                    <div class="icon-hnr pa-2" v-on:click="dateEndBox = true">
                      <v-icon>
                        mdi-calendar
                      </v-icon>
                    </div>
                  </v-row>
                  <span class="validate-error text-danger text-danger">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
            </v-col>
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.describe }}
              </div>
              <div class="ml-4 mt-5">
                <v-row align="center">
                  <hnr-textarea
                    :text="discountCodeInfo.description"
                    v-model="discountCodeInfo.description"
                    :label="lang.label.describe"
                    class="input-textArea"
                  ></hnr-textarea>
                </v-row>
              </div>
            </v-col>
            <v-dialog v-model="dateStartBox" max-width="1000px">
              <v-card>
                <v-container>
                  <v-row>
                    <v-col cols="5"></v-col>
                    <v-col cols="5">
                      <span class="title schel text-h3">{{
                        lang.title.startDatePicker
                      }}</span>
                    </v-col>
                    <v-col cols="2"></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="2"></v-col>
                        <v-col cols="4">
                          <ValidationProvider
                            name="picker"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-date-picker
                              v-model="pickerStart"
                            ></v-date-picker>
                            <span
                              class="validate-error text-danger text-danger"
                            >
                              {{ errors[0] }}
                            </span>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="4">
                          <v-time-picker
                            v-model="timerStart"
                            format="ampm"
                          ></v-time-picker>
                        </v-col>
                        <v-col cols="2"></v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="8"></v-col>
                    <v-col cols="4">
                      <v-btn
                        large
                        @click="dateStartBox = false"
                        class="btn-cancel"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        large
                        @click="createStartDate()"
                        class="btn-submit white--text"
                        style="background-color: black"
                      >
                        Save
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dateEndBox" max-width="1000px">
              <v-card>
                <v-container>
                  <v-row>
                    <v-col cols="5"></v-col>
                    <v-col cols="5">
                      <span class="title schel text-h3">{{
                        lang.title.endDatePicker
                      }}</span>
                    </v-col>
                    <v-col cols="2"></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="2"></v-col>
                        <v-col cols="4">
                          <ValidationProvider
                            name="picker"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-date-picker v-model="pickerEnd"></v-date-picker>
                            <span
                              class="validate-error text-danger text-danger"
                            >
                              {{ errors[0] }}
                            </span>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="4">
                          <v-time-picker
                            v-model="timerEnd"
                            format="ampm"
                          ></v-time-picker>
                        </v-col>
                        <v-col cols="2"></v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="8">
                      <span class="validate-error text-danger text-danger">
                        {{ dateErrorMessage }}
                      </span>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        large
                        @click="dateEndBox = false"
                        class="btn-cancel"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        large
                        @click="createEndDate()"
                        class="btn-submit white--text"
                        style="background-color: black"
                      >
                        Save
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
            <v-col cols="12">
              <span class="ml-14 pl-13 text-left red--text"
                >(*) : Bắt buộc nhập</span
              >
            </v-col>
            <v-col class="mx-auto text-right" cols="8">
              <hnr-button
                type="submit"
                form="check-form"
                rounded
                width="200"
                text="Cập nhật mã khuyến mại"
                color="blue"
                white-text="true"
              ></hnr-button>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import HnrButton from "../../../components/elements/hnr-button";
import HnrTextarea from "../../../components/elements/hnr-textarea";
import {
  UPDATE_DISCOUNT_CODE,
  GET_DETAIL_DISCOUNT_CODE,
  GENERATE_CODE
} from "@/store/discountCodes.module";
import { GET_LIST_COURSE } from "@/store/courses.module";
import { mapGetters } from "vuex";

export default {
  components: { HnrTextarea, HnrButton },
  data: () => ({
    inputMoney: {
      label: "",
      placeholder: "Nhập mệnh giá theo phần trăm hoặc theo VNĐ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: ""
    },
    options: {
      locale: "pt-BR",
      prefix: "VNĐ$",
      suffix: "",
      length: 100,
      precision: 0
    },
    isGetAllCourse: false,
    pickerStart: new Date().toISOString().substr(0, 10),
    timerStart: "11:15",
    dateStartBox: false,
    pickerEnd: new Date().toISOString().substr(0, 10),
    timerEnd: "11:15",
    dateEndBox: false,
    paymentNumberErrorMessage: "",
    lang: {
      title: {
        course: "Khóa học",
        amountUsed: "Số lần sử dụng",
        code: "Mã khuyến mại",
        startDate: "Ngày bắt đầu",
        endDate: "Ngày kết thúc",
        describe: "Mô tả",
        money: "Số tiền",
        all: "Tất cả",
        startDatePicker: "Ngày bắt đầu",
        endDatePicker: "Ngày kết thúc",
        payment: "Phương thức thanh toán"
      },
      label: {
        course: "Chọn khóa học",
        amountUsed: "0",
        code: "Nhập mã khuyến mại",
        startDate: "2021/05/12 17:01",
        endDate: "2021/15/12 15:02",
        describe: "Nhập mô tả"
      }
    },
    paymentMethod: [
      {
        value: 1,
        label: "Tiền mặt(đ)"
      },
      {
        value: 2,
        label: "Phần trăm(%)"
      }
    ],
    boxListCourse: true,
    coursePrice: "",
    dateErrorMessage: "",
    usedNumberErrorMessage: "",
    paymentNumber: ""
  }),
  created() {
    let id = this.$route.params.id;
    this.$store.dispatch(GET_DETAIL_DISCOUNT_CODE, id);
    this.$store.dispatch(GET_LIST_COURSE);
  },
  watch: {
    isGetAllCourse: function(val) {
      if (val) {
        this.courseInput = this.courses;
      } else {
        while (this.courseInput.length > 0) {
          this.courseInput.pop();
        }
        this.$store.dispatch(GET_LIST_COURSE);
      }
    },
    "discountCodeInfo.course.price": function(val) {
      localStorage.setItem("course", JSON.stringify(parseInt(val)));
      this.coursePrice = parseInt(val);
    },
    "discountCodeInfo.max_of_use": function(val) {
      if (isNaN(val)) {
        this.usedNumberErrorMessage = "Ô này không được là chữ cái";
      } else if (val < 0) {
        this.usedNumberErrorMessage = "Giá trị nhập phải lớn hơn 0";
      } else {
        this.usedNumberErrorMessage = "";
      }
    },
    "discountCodeInfo.type": function() {
      this.coursePrice = localStorage.getItem("course");
      this.paymentNumber = "";
    },
    "discountCodeInfo.amount_money": function(val) {
      this.paymentNumber = val;
    },
    paymentNumber: function(val) {
      if (!val) {
        this.coursePrice = JSON.parse(localStorage.getItem("course"));
        this.paymentNumberErrorMessage = "Ô này không được bỏ trống";
      } else if (isNaN(val)) {
        this.paymentNumberErrorMessage = "Ô này không được là chữ cái";
      } else if (val < 0) {
        this.paymentNumberErrorMessage = "Giá trị nhập phải lớn hơn 0";
      } else if (this.discountCodeInfo.type === 2) {
        if (val > 100) {
          this.paymentNumberErrorMessage = "Không được vượt quá 100%";
        } else {
          this.paymentNumberErrorMessage = "";
          if (val) {
            if (this.discountCodeInfo.type == 1) {
              this.coursePrice =
                this.discountCodeInfo.course.price - parseInt(val);
              this.discountCodeInfo.amount_money = val;
            } else {
              this.coursePrice =
                this.discountCodeInfo.course.price *
                ((100 - parseInt(val)) / 100);
              this.discountCodeInfo.amount_money = val;
            }
          } else {
            this.coursePrice = JSON.parse(localStorage.getItem("course"));
          }
        }
      } else {
        this.paymentNumberErrorMessage = "";
        if (val) {
          if (this.discountCodeInfo.type == 1) {
            this.coursePrice =
              this.discountCodeInfo.course.price - parseInt(val);
            this.discountCodeInfo.amount_money = val;
          } else {
            this.coursePrice =
              this.discountCodeInfo.course.price *
              ((100 - parseInt(val)) / 100);
            this.discountCodeInfo.amount_money = val;
          }
        } else {
          this.coursePrice = JSON.parse(localStorage.getItem("course"));
        }
      }
    },
    codeInput: function(val) {
      this.discountCodeInfo.discount_code = val;
    }
  },
  computed: {
    ...mapGetters({
      codeInput: "getCode",
      courses: "listCourse",
      discountCodeInfo: "getDetailDiscountCode"
    }),

    courseCurrencyPrice : function (){
      return this.coursePrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  },
  methods: {
    createStartDate() {
      this.discountCodeInfo.start_time =
        this.pickerStart + " " + this.timerStart;
      this.dateStartBox = false;
      this.dateEndBox = true;
    },
    createEndDate() {
      if (this.pickerEnd.split("-")[0] < this.pickerStart.split("-")[0]) {
        this.dateErrorMessage = "Năm không hợp lệ";
      } else {
        if (this.pickerEnd.split("-")[0] == this.pickerStart.split("-")[0]) {
          if (this.pickerEnd.split("-")[1] < this.pickerStart.split("-")[1]) {
            this.dateErrorMessage = "Tháng không hợp lệ";
          } else if (
            this.pickerEnd.split("-")[1] == this.pickerStart.split("-")[1]
          ) {
            if (
              this.pickerStart.split("-")[2] >= this.pickerEnd.split("-")[2]
            ) {
              this.dateErrorMessage = "Ngày không hợp lệ";
            } else {
              this.saveEndDate();
            }
          } else {
            this.saveEndDate();
          }
        } else {
          this.saveEndDate();
        }
      }
    },
    saveEndDate() {
      this.dateErrorMessage = " ";
      this.discountCodeInfo.end_time = this.pickerEnd + " " + this.timerEnd;
      this.dateEndBox = false;
    },
    generateCode() {
      this.$store.dispatch(GENERATE_CODE, "");
    },
    async updateDiscountCode() {
      if (!this.discountCodeInfo.amount_money) {
        this.paymentNumberErrorMessage = "Ô này không được bỏ trống";
      } else {
        if (this.discountCodeInfo.type == 1) {
          this.discountCodeInfo.type = 1;
        } else {
          this.discountCodeInfo.type = 2;
        }
        if (this.discountCodeInfo.max_of_use < 0) {
          this.usedNumberErrorMessage = "Giá trị nhập phải lớn hơn 0";
        } else if (this.discountCodeInfo.type == 2) {
          if (this.paymentNumber > 100) {
            this.paymentNumberErrorMessage = "không được vượt quá 100%";
          } else if (this.discountCodeInfo.amount_money < 0) {
            this.paymentNumberErrorMessage = "Giá trị nhập phải lớn hơn 0";
          } else {
            this.updateCodes();
          }
        } else {
          this.updateCodes();
        }
      }
    },
    async updateCodes() {
      this.discountCodeInfo.course_id = this.discountCodeInfo.course.id;
      await this.$store.dispatch(UPDATE_DISCOUNT_CODE, this.discountCodeInfo);
      this.$router.push({
        path: "/promotion/list"
      });
    },
    removeCourse(item) {
      const index = this.courseInput.indexOf(item);
      if (index >= 0) this.courseInput.splice(index, 1);
    },
  },
  filters: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  }
};
</script>

<style lang="scss">
.checkbox-course {
  padding: 9px !important;
  position: relative;
  left: -118px;
  border: 1px solid #757575;
  background: #e0e0e0;
  border-bottom: 1px solid lightgray;

  .v-input__control {
    .v-input__slot {
      label {
        margin-bottom: 0;
      }
    }
  }
}

.checkbox-amount-used {
  height: 45px;
  padding: 9px !important;
  position: relative;
  left: -10%;
  border: 1px solid #757575;
  background: #e0e0e0;
  /* border-bottom: 1px solid lightgray; */
  margin-top: 18px;

  .v-input__control {
    .v-input__slot {
      label {
        margin-bottom: 0;
      }
    }
  }
}
.money-box-input {
  width: 53%;
  .v-input__control {
    height: 66px;
    .v-input__slot {
      fieldset {
        height: 45px;
      }
    }
    .v-text-field__prefix {
      display: none;
    }
    input {
      padding-bottom: 26px;
    }
  }
}
.money-box {
  position: relative;
}

.input-money {
  .v-input__control {
    .v-input__slot {
      width: 86.5%;
    }
  }
}

.input-amount-used {
  .v-input__control {
    .v-input__slot {
      width: 107%;
    }
  }
}

.input-textArea {
  .v-input__control {
    .v-input__slot {
      width: 87.5%;
    }
  }
}

.input-hnr {
  .v-input__control {
    .v-input__slot {
      width: 91.5%;
    }
  }
}
.money__icon-fix {
  left: -43px !important;
}
.autoCompleteBox {
  .v-input__control {
    .v-input__slot {
      width: 70%;
    }
  }
}

.icon-hnr {
  height: 43px;
  position: relative;
  left: -12.5%;
  bottom: 17px;
  border: 1px solid #757575;
  background: #e0e0e0;
  /* border-bottom: 2px solid lightgray; */
  margin-bottom: -3px;
}
</style>
